
import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Title from '../common/Title'
import backgroundimage from "../../assets/images/description.webp"
import Button from '../common/Button'

const description = "オーバーサスペンションは、車体が制御しきれない細かな振動や急激な路面の変化といった予想できないノイズに対し、アクティブに反応し振動を減衰させることで、サスペンションユニットの性能を最大限引き出します。車体のコントロール性能を高めることができるパーツです。"

const DescriptionStyle = styled.section`
    background: #000;
    width: 100%;
    height: 100%;
    position: relative;
    .outer-container {
        background-image: url(${backgroundimage});
        background-size: cover;
        background-position: 20%;
        .description-container {
            color: #fff;
            background: rgba(0,0,0,0.4);
            width: 100%;
            height: 100%;
            padding-top: 20px;
            padding-bottom: 60px;
            .description-text {
                padding-left: 10%;
                padding-right: 10%;
                .description-description {
                    font-family: var(--gothic);
                    font-weight: 300;
                    line-height: 2em;
                    font-size: 0.9rem;
                    margin-bottom: 10px;
                }
            }
        }
    }

    @media screen and (min-width:1040px){
        .description-container {
            .description-text {
                margin: 0 0 0 auto;
                padding-left: 10%;
                width: 600px;
            }
        }
    }
    @media screen and (max-width:540px){
        .description-container {
            .description-text {
                padding-left: 0 !important;
                padding-right: 0 !important;
                padding: 0 15px !important;
                line-height: 1.5em;
                padding-top: 20px;
                p{
                    font-size: 0.8rem;
                }
                .btn-flat-border {
                    font-size: 0.6rem;
                    padding: 0.6em 1em;
                }
            }
        }
    }
`

function Description() {
    return (
        <DescriptionStyle>
            <div className="outer-container" loading='lazy'>
                <div className="description-container">
                    <div className="description-text">
                        <Title title='Over Suspension' subtitle='サスペンションの新しいテクノロジー' darkMode={true}/>
                        <p className='description-description'>{description}</p>
                        <Link to='/product'>
                            <Button isDarkMode={true}>製品カテゴリページへ</Button>
                        </Link>
                    </div>
                </div>
            </div>
        </DescriptionStyle>
    )
}

export default Description
