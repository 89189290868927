import React from 'react'
import styled from 'styled-components'

const NewsCardStyle = styled.div`
    background: #fafafa;
    border-radius: 2px;
    padding: 25px;
    font-family: var(--gothic);
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .news-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        .news-title {
            font-size: 16px;
            font-weight: 400;
            color: #5a5a5a;
        }
        .news-date {
            font-size: 14px;
            color: #777;
            padding: 10px 0;
        }
        .news-description {
            p {
                font-size: 14px;
                color: #5a5a5a;
                line-height: 1.5;
                letter-spacing: 0.05em;
            }
        }
        .news-continue {
            margin-top: 10px;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .news-continue-text {
                font-size: 14px;
            }
            .news-continue-icon {
                margin: 0 5px;
                transition: var(--transition-timing);
                svg {
                    width: 14px;
                    width: 7px;
                    fill: #5a5a5a;
                }
            }
        }
    }
    @media (max-width: 540px) {
        .news-image {
            display: none;
        }
        .news-content {
            margin: 0;
        }
    }
    &:hover {
        .news-content {
            .news-continue {
                .news-continue-text {
                    color: #e84343;
                }
                .news-continue-icon {
                    transform: translate(-4px, 0px);
                    svg {
                        fill: #ea5050
                    }
                }
            }
        }
    }
`

function NewsCard({title, description, date}) {
    return (
        <NewsCardStyle>
            <div className='news-content'>
                <div className='news-title'>{title}</div>
                <div className='news-date'>{date}</div>
                <div className='news-description'>{description.length > 100 ? description.slice(0,100) + '...' : description}</div>
            </div>
        </NewsCardStyle>
    )
}

export default NewsCard
