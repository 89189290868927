import React from "react"
import Layout from '../components/layout/layout'
import FirstView from '../components/index/FirstView'
import Description from '../components/index/Description'
import News from '../components/index/News'
import About from '../components/index/About'
import SpCatchcopy from '../components/index/SpCatchcopy'

const IndexPage = () => {
    return (
        <Layout pageTitle="Home" isPageTop={true}>
            <FirstView />
            <SpCatchcopy />
            <About />
            <Description />
            <News />
        </Layout>
    )
}

export default IndexPage