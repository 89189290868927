import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Title from '../common/Title'
import PageComponent from '../common/PageComponent'
import NewsCard from '../common/NewsCard'
import Button from '../common/Button'

import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

const pageComponentStyle = {
    background: '#eee'
}

const NewsStyle = styled.section`
    margin-bottom: 50px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .news-container {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 50%);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        @media screen and (max-width: 1040px) {
            grid-template-rows: repeat(3, auto);
        }
        @media screen and (max-width: 750px) {
            grid-template-columns: repeat(1, 100%);
            grid-template-rows: repeat(3, auto);
        }
    }
    .news-button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0;
    }
`

function News() {
    const data = useStaticQuery(graphql`
    query {
        allContentfulNews ( sort: { fields: date, order: DESC }, limit: 4 ) {
            edges {
                node {
                    title 
                    slug 
                    date 
                    content { 
                        raw 
                        references {
                            title
                            contentful_id
                            fixed(width: 400) {
                                width
                                height
                                src
                           }
                        }
                    }
                }
            }
        }
    }
    `)
    return (
        <PageComponent styles={pageComponentStyle}>
            <NewsStyle>
                <div style={{width: '100%', maxWidth: '600px'}}>
                    <Title title='News' subtitle='最新情報はこちら' style={{width: '100%'}}/>
                </div>
                <div className='news-container'>
                    {data.allContentfulNews.edges.map((edge, index) => (
                        <NewsCard 
                            key={index}
                            title={edge.node.title}
                            description={documentToReactComponents(JSON.parse(edge.node.content.raw))}
                            date={edge.node.date}
                        />
                    ))}
                </div>
                <div className='news-button'>
                    <Button>
                        <Link to='/news/1' style={{color: '#5a5a5a'}}>News一覧を見る</Link>
                    </Button>
                </div>
            </NewsStyle>
        </PageComponent>
    )
}
export default News

