import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Logo from '../../assets/images/svg/logo.svg'
import Title from '../common/Title'
import PageComponent from '../common/PageComponent'

const componentStyle = {
    background: '#fafafa', 
    alignItems: 'flex-start'
}

const AboutStyle = styled.div`
    padding-bottom: 30px;
    width: 100%;
    box-sizing: border-box;
    background: #fafafa;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    .about-container {
        font-family: var(--gothic);
        .about-texts {
            max-width: 700px;
            .text-container {
                padding-bottom: 32px;
                .about-subtitle {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 2;
                    padding-bottom: 5px;
                    color: #444;
                }
                .about-text {
                    color: #5a5a5a;
                    font-size: 14px;
                    line-height: 1.5;
                    letter-spacing: 0.05em;
                }
            }
        }
    }
    .about-logo-container {
        flex-grow: 1;
        width: auto;
        display: flex;
        justify-content: center;
        .about-logo {
            width: 300px;
            padding: 150px 30px;
        }
    }
    @media screen and (max-width: 800px) {
        flex-direction: column;
        align-items: center;
        .about-logo-container {
            .about-logo {
                padding: 30px 0;
            }
        }
    }
`

function About() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [width, setWidth] = useState();
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        if (!isLoaded) {
            setIsLoaded(true)
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        <PageComponent styles={componentStyle}>
            <AboutStyle>
                <div className='about-container'>
                    <Title title='About Us' subtitle='シュプリームテクノロジーについて' />
                    <div className='about-texts'>
                        <div className='text-container'>
                            <h3 className='about-subtitle'>THE BRAND</h3>
                            <p className='about-text'>
                                シュプリームテクノロジーの一連の製品は、30年以上にも及ぶメカニックとしての経験と
                                {width > 500 && (<br/>)}
                                高精度のマシニング技術によって獲得された、先進的で型破りな製品です。
                            </p>
                        </div>
                        <div className='text-container'>
                            <h3 className='about-subtitle'>OVER SUSPENSION PROJECT</h3>
                            <p className='about-text'>
                                本製品は、弊社がモータースポーツ界と関わっていたとき、サスペンションに多くの異常な動作が見受けられたことをきっかけにして生まれました。これらの異常な動作はサスペンションの動きから観察される外部からの作用に起因しています。
                            </p>
                        </div>
                    </div>
                </div>
                <div className='about-logo-container'>
                    <Logo className='about-logo'/>
                </div>
            </AboutStyle>
        </PageComponent>
    )
}
export default About
