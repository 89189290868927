import React from 'react'
import styled from 'styled-components'
import Quote from '../../assets/images/svg/quote-left.svg'

const CatchCopyStyle = styled.section`
    display: none;
    @media screen and (max-width:540px){
        display: block;
    }
    box-sizing: border-box;
    width: 100%;
    padding: 50px 20px 100px;
    background: var(--white-color-1);
    position: relative;
    height: 170px;
    .sp-catchcopy {
        box-sizing: border-box;
        width: 315px;
        font-family: var(--mincho);
        letter-spacing: -0.05em;
        font-size: 14px;
        font-weight: 400;
        color: var(--gray-color-1);
        line-height: 1.8;
        z-index: 100;
        background: transparent;
        position: absolute;
        margin-left: 50%;
        transform: translateX(-50%);
    }
    .quote-img-container {
        z-index: 10;
        width: 50px;
        height: 50px;
        position: absolute;
        .quote-img {
            transform: translate(-10px,-20px);
            width: 100%;
            height: 100%;
            fill: #ddd;
        }
    }
    .bar-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;
        transform: translateY(50px);
        .catchcopy-bar {
            width: 100px;
            height: 1px;
            background: var(--supreme-red);
            display: block;
            transform: translateY(30px);
        }
    }
`

function CatchCopy() {
    return (
        <CatchCopyStyle>
            <div className='quote-img-container'>
                <Quote className="quote-img" />
            </div>
            <h1 className='sp-catchcopy'>
                オーバーサスペンションは革新的なアイデアです。<br/>
                サスペンションユニットの性能を最大限引き出し、安全性・コントロールを高めます。
            </h1>
            <div className='bar-container'>
                <span className='catchcopy-bar'></span>
            </div>
        </CatchCopyStyle>
    )
}
export default CatchCopy