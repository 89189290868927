import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from 'gatsby'

const FirstViewStyle = styled.section`
    height: calc(100vh - 60px);
    box-sizing: border-box;
    overflow: hidden;
    background: var(--black-color-1);
    justify-content: center;
    position: relative;
    .catchcopy {
        font-family: var(--gothic);
        letter-spacing: 0.1rem;
        line-height: 2rem;
        font-size: 1.6em;
        font-weight: 700;
        position: absolute;
        top: 25%;
        left: 10%;
        color: #fff;
        z-index: 10;
    }
    .imageContainer{
        height: calc(100vh);
        width: 100%;
        .image {
            width: 100%;
            overflow: hidden;
            height: 100%;
            height: calc(100vh);
        }
    }
    @media screen and (min-width:1040px){
        .catchcopy{
            line-height: 4rem;
            font-size: 2rem;
            position: absolute;
            top: 55%;
            left: 10%;
        }
        display: flex;
        align-items: flex-start;
        padding:0 0 0 10%;
        .imageContainer{
            margin: 0 0 0 10%;
        }
        }
    }
    @media screen and (max-width:540px){
        height: 500px;
        .catchcopy{
            font-size: 1.2em;
            display: none;
        }
        .imageContainer{
            height: 500px;
            .image {
                height: 500px;
            }
        }
    }
`


function FirstView() {
    const [isLoaded, setIsLoaded] = useState(false)
    const [width, setWidth] = useState();
    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        if (!isLoaded) {
            setIsLoaded(true)
            handleResize();
        }
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
    }, []);
    const data = useStaticQuery(graphql`
    query {
        firstview: file(relativePath: { eq: "firstview.webp" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 1600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
        firstviewsp: file(relativePath: { eq: "firstview_sp.webp" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
    `)
    const [image1, image2] = [getImage(data.firstview), getImage(data.firstviewsp)]
    return (
        <FirstViewStyle>
            <h1 className="catchcopy">オーバーサスペンションで<br/>かつてない追従性を</h1>
            <div className="imageContainer">
                {width > 500 ? (
                    <GatsbyImage
                        className="image"
                        image={image1}
                    />
                    ) : (
                    <GatsbyImage
                        className="image"
                        image={image2}
                    />
                )}
            </div>
        </FirstViewStyle>
    )
}

export default FirstView
